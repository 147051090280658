import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import Loading from '@ai/common-ui/src/components/CLoading/CLoading.component';
import Benefits from '@ai/common-ui/src/components/Benefit/BenefitList.component';
import { BenefitProps } from '@ai/common-ui/src/components/Benefit/Benefit.component';
import Hero from '@ai/common-ui/src/components/CHero/CHero.component';
import HowItWorks from '@ai/common-ui/src/components/CHowItWorks/CHowItWorks.component';
import Featured from '@ai/common-ui/src/components/CFeatured/CFeatured.component';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import FeaturedVideo from '../videos/featured.mp4';
import { getTranslationNamespace,  } from '../constants';

const FeaturedImage = (
  <StaticImage
    src='../images/screenshoot_alpha.png'
    alt='Featured'
    className='type'
  />
);

const FeaturedImageMobile = (
  <StaticImage
    src='../images/screenshoot_alpha_mobile.png'
    alt='Featured '
    className='type'
  />
);

const StepsOne = (
  <StaticImage
    src='../images/steps/a-profesional.png'
    alt='Step1'
    className='type'
  />
);

const StepsTwo = (
  <StaticImage
    src='../images/steps/ai-assitance.png'
    alt='Step2'
    className='type'
  />
);

const StepsThree = (
  <StaticImage
    src='../images/steps/dynamic-scene.png'
    alt='Step2'
    className='type'
  />
);

const StepsFour = (
  <StaticImage
    src='../images/steps/congrats.png'
    alt='Step2'
    className='type'
  />
);

const useStyles = makeStyles(theme => ({
  boxSpacing: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(20)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6.25),
      marginBottom: theme.spacing(6.25)
    }
  }
}));

const IndexPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(getTranslationNamespace());
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const heroContent = {
    'header-p1': t('joinNow'),
    'header-p2': t('createRaffle'),
    description: t('experienceThrill'),
    'primary-action': t('joinWaitlist')
  };

  const benefitsContent: Array<BenefitProps> = [
    {
      heading: t('benefit1Heading'),
      headerP1: t('benefit1HeaderP1'),
      headerP2: t('benefit1HeaderP2'),
      description: t('benefit1Desc'),
      button: t('getChanceWinNFT'),
      image: (
        <StaticImage
          src={'../images/agent_v4.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row'
    },
    {
      heading: t('benefit2Heading'),
      headerP1: t('benefit2HeaderP1'),
      headerP2: t('benefit2HeaderP2'),
      description: t('benefit2Desc'),
      button: t('createRaffleGame'),
      image: (
        <StaticImage
          src={'../images/cv_agent.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row-reverse'
    },
    {
      heading: t('benefit3Heading'),
      headerP1: t('benefit3HeaderP1'),
      headerP2: t('benefit3HeaderP2'),
      description: t('benefit3Desc'),
      button: t('benefit3CTA'),
      image: (
        <StaticImage
          src={'../images/robot_programmer.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row'
    }
  ];

  const stepData = [
    {
      number: '1',
      text: t('howItWorks.step1.title'),
      image: StepsOne
    },
    {
      number: '2',
      text: t('howItWorks.step2.title'),
      image: StepsTwo
    },
    {
      number: '3',
      text: t('howItWorks.step3.title'),
      image: StepsThree
    },
    {
      number: '4',
      text: t('howItWorks.step4.title'),
      image: StepsFour
    }
  ];

  return (
    <Layout>
      <div style={{ width: '100%' }}>
        <Box>
          <Hero content={heroContent} />
        </Box>
        <Box>
          <Featured
            videoSource={FeaturedVideo}
            image={FeaturedImage}
            mobileImage={FeaturedImageMobile}
          />
        </Box>
        <Box className={classes.boxSpacing}>
          <HowItWorks steps={stepData} />
        </Box>
        <Box>
          <Benefits data={benefitsContent} />
        </Box>
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title={'Home'} />;

export default IndexPage;
