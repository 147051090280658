// CHero.component.tsx
import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import SubscribeModalButton from '../CButton/SubscribeModalButton.component';

export interface HeroProps {
  content: {
    'header-p1': string;
    'header-p2': string;
    description: string;
    'primary-action': string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  styledSection: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    }
  },
  responsiveHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem'
    }
  }
}));

const Hero: FC<HeroProps> = props => {
  const { content } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <section className={classes.styledSection}>
      <Container>
        <Box textAlign='center' color='common.white'>
          <Typography component='h1' gutterBottom style={{ marginBottom: theme.spacing(4) }}>
            <p>
              <Typography variant='h1' color='primary' component='span' className={classes.responsiveHeader}>
                {content['header-p1']}
              </Typography>
            </p>
            <p>
              <Typography variant='h1' color='secondary' component='span' className={classes.responsiveHeader}>
                {content['header-p2']}
              </Typography>
            </p>
          </Typography>
          <Box mt={4}>
            <Grid container direction='column' alignItems='center'>
              <Grid item xs={8}>
                <Typography variant='subtitle1' color='primary' paragraph>
                  {content.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={4}>
            <SubscribeModalButton endIcon={<NavigateNext fontSize='large' />}>
              {content['primary-action']}
            </SubscribeModalButton>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Hero;
