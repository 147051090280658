import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1200px',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: theme.spacing(4)
  },
  header: {
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    },
    fontWeight: 'bold',
    fontSize: '2.5rem'
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    },
    marginBottom: theme.spacing(4)
  },
  stepNumber: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1)
  },
  stepDescription: {
    color: '#ffffff',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    }
  },
  image: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

type Step = {
  number: string;
  text: string;
  image: React.ReactNode;
};

interface HowItWorksProps {
  steps: Step[];
}

const HowItWorks: React.FC<HowItWorksProps> = ({ steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.header}>HOW IT WORKS</Typography>
      <Grid container spacing={4} justifyContent='center'>
        {steps.map((step, index) => (
          <Grid
            item key={index} xs={12} sm={6}
            md={3}
          >
            <div className={classes.imageContainer}>
              {step.image}
            </div>
            <Typography className={classes.stepNumber}>{step.number}</Typography>
            <Typography className={classes.stepDescription}>{step.text}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HowItWorks;
