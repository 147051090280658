import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Video from '../CVideo/CVideo.component';

const useStyles = makeStyles(theme => ({
  styledVideo: {
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      minHeight: 500,
      marginBottom: theme.spacing(6)
    }
  },
  styledSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  styledBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  styledVideoBox: {
    position: 'relative',
    '& video': {
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '13rem',
        maxWidth: '22rem'
      }
    }
  },
  styledVideoOverlays: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  video: {
    width: '60rem',
    [theme.breakpoints.down('sm')]: {
      width: '23rem'
    }
  },
  desktopImage: {
    opacity: 1,
    maxWidth: '75rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileImage: {
    display: 'none',
    opacity: 1,
    maxWidth: '20rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
}));

export interface FeaturedProps {
  videoSource: string;
  videoSourceOverlay?: string;
  image?: React.ReactNode;
  mobileImage?: React.ReactNode;
}

const Featured: React.FC<FeaturedProps> = ({ videoSource, image, mobileImage, videoSourceOverlay }) => {
  const classes = useStyles();
  const [showPoster, setShowPoster] = useState(true);

  const handlePosterClick = videoSourceOverlay ? () => setShowPoster(false) : undefined;

  return (
    <section className={classes.styledSection} onClick={handlePosterClick}>
      <Container>
        <Box className={classes.styledBox}>
          <Box className={classes.styledVideoBox}>
            <Box className={classes.styledVideoOverlays}>
              {(showPoster || !videoSourceOverlay) && (
                <>
                  {image && <div className={classes.desktopImage}>{image}</div>}
                  {mobileImage && <div className={classes.mobileImage}>{mobileImage}</div>}
                </>
              )}
              {!showPoster && videoSourceOverlay && (
                <Video
                  autoPlay={true}
                  muted={false}
                  controls={true}
                  videoSource={videoSourceOverlay}
                  className={classes.video}
                />
              )}
            </Box>
            <Video videoSource={videoSource} className={classes.styledVideo} />
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Featured;
